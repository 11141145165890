import VerificationReport from ".";
import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import projectApi from "../../../apis/project/index";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MySpin from "../../../components/MySpin";
import { useUnmount } from "ahooks";
import { useDispatch, useSelector } from "react-redux";

const VerificationReportPage = (props) => {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [notFinished, setNotFinished] = useState(false);
  const timer = useRef(null);
  const navigate = useNavigate();

  const projectInfo = useSelector(
    (state) => state.Project.projectInfo
  );

  const getRiskTaskProcess = (notLoading) => {
    if(!notLoading){
      setPageLoading(true);
    }
    projectApi.getRiskTaskProcess(id).then((res) => {
      let notFinished = false;
      if (res.code === 200) {
        notFinished = !!res.data.data?.some(
          (_) => _?.risk_complete_flag === 0 || _?.risk_complete_flag === 10
        );
      }
      setPageLoading(false);
      setNotFinished(notFinished);

      if(timer.current){
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(()=>{
        getRiskTaskProcess(true)
      }, 3 * 1000)
      console.log('是否在核查中------->',notFinished);
    });
  };

  useEffect(() => {
    if(projectInfo?.status === 'CDD_PRJ_STATUS_CLS'){
      navigate(`/company/${id}`);
      return
    }
    getRiskTaskProcess();
  }, []);

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  return (
    <div className="verificationReportPage">
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{ height: "100%", width: "1000px" }}
      >
        {pageLoading ? (
          <div
            style={{
              width: "1000px",
              minHeight: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              padding: "20px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MySpin></MySpin>
          </div>
        ) : (
          <VerificationReport notFinished={notFinished} getRiskTaskProcess={getRiskTaskProcess}></VerificationReport>
        )}
      </Scrollbars>
    </div>
  );
};
export default VerificationReportPage;
