import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import projectApi from "../../../apis/project";
import { message, Tag, Tooltip } from "antd";
import {
  SettingOutlined,
  HourglassOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import VerificationReport from "../VerificationReport ";
import MySpin from "../../../components/MySpin";
import { useGetState, useUnmount } from "ahooks";
import { clone } from "lodash";
import hczPng from "../../../assets/hcz.png";

const RiskVerification = (props) => {
  const { id } = useParams();
  const [configList, setConfigList, getConfigList] = useGetState([]);
  const [riskTaskProcessObj, setRiskTaskProcessObj, getRiskTaskProcessObj] =
    useGetState({});
  const [isFinished, setIsFinished] = useState(false);
  const [finishedCount, setFinishedCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [verificationTime, setVerificationTime] = useState(null);

  const progressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );

  const projectInfo = useSelector(
    (state) => state.Project.projectInfo
  );

  const timer = useRef(null);

  /* 模拟进度 */
  const getProcessFunc = () => {
    let obj = clone(getRiskTaskProcessObj());
    let list = getConfigList();
    let finishedCount = 0;
    let isFinished = true;
    for (let index = 0; index < list.length; index++) {
      const item = list[index];
      if (obj[item?.risk_item_name]?.risk_complete_desc === "未完成") {
        obj[item?.risk_item_name].risk_complete_desc = "进行中";
        isFinished = false;
        break;
      }
      if (obj[item?.risk_item_name]?.risk_complete_desc === "进行中") {
        obj[item?.risk_item_name].risk_complete_desc = "已完成";
      }

      finishedCount += obj[item?.risk_item_name]?.risk_count;
    }
    setFinishedCount(finishedCount);
    setRiskTaskProcessObj(obj);
    setIsFinished(isFinished);
  };

  const getRiskTaskProcess = (notLoading) => {
    if (!notLoading) {
      setPageLoading(true);
    }
    projectApi.getRiskTaskProcess(id).then((res) => {
      console.log("√getRiskTaskProcess", res);
      let obj = {};
      let isFinished = true;
      let finishedCount = 0;
      if (res.code === 200) {
        res.data.data?.forEach((_) => {
          obj[_.task_risk_name] = _;
          if (_?.risk_complete_flag === 0 || _?.risk_complete_flag === 10) {
            isFinished = false;
          }
          if (_?.risk_complete_flag === 1) {
            finishedCount += _?.risk_count;
          }
        });
      }
      setVerificationTime(res.data?.last_check_time);
      setPageLoading(false);
      setFinishedCount(finishedCount);
      setIsFinished(isFinished);
      setRiskTaskProcessObj(obj);

      if(timer.current){
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        getRiskTaskProcess(true);
      }, 3 * 1000);

    });
  };

  const getRiskItemsConfigs = () => {
    projectApi.getRiskItemsConfigs().then((res) => {
      console.log("getRiskItemsConfigs", res);
      if (res.code === 200) {
        setConfigList(res.data.data || []);
      } else {
        setConfigList([]);
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    getRiskItemsConfigs();
    getRiskTaskProcess();
  }, []);

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  if (pageLoading) {
    return (
      <div className="riskVerification">
        <MySpin></MySpin>
      </div>
    );
  }

  return (
    <div className="riskVerification">
      {/* 核查完成 */}
      {isFinished ? (
        <VerificationReport
          isInner={true}
          getRiskTaskProcess={getRiskTaskProcess}
        ></VerificationReport>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          {/* 核查中,未完成 */}
          <div className="configContent">
            <div className="dateBox">
              <span className="dateSpan">
                核查时间 {verificationTime || "-"}
              </span>
              <span className="dateSpan">
                核查项{" "}
                <span className="numSpan">
                  {configList?.reduce((a, b) => a + b?.children?.length, 0)}
                </span>{" "}
                条
              </span>
            </div>

            <div className="loadingTextBox">
              <div className="imgItemBox">
                {progressCode !== "CDD_FIRST_PROCESS_6_FINISH" ? (
                  <FieldTimeOutlined />
                ) : (
                  <img src={hczPng} alt="" />
                )}
              </div>
              {progressCode !== "CDD_FIRST_PROCESS_6_FINISH"
                ? (projectInfo?.status === 'CDD_PRJ_STATUS_CLS' ? '项目已关闭，还未启动风险核查' : "正在获取数据，还未启动风险核查")
                : `正在核查中，已完成${finishedCount}条`}
            </div>

            <div className="configItemsBox">
              {configList?.map((_, index) => {
                let className =
                  index === configList?.length - 1 || (index + 1) % 4 === 0
                    ? "configItemBox"
                    : "configItemBox configActiveItemBox";
                if (progressCode === "CDD_FIRST_PROCESS_6_FINISH") {
                  //已完成
                  if (
                    (riskTaskProcessObj[_?.risk_item_name] || {})
                      ?.risk_complete_flag === 1
                  ) {
                    className = className + " configEndActiveItemBox";
                  }
                  //进行中
                  if (
                    (riskTaskProcessObj[_?.risk_item_name] || {})
                      ?.risk_complete_flag === 10
                  ) {
                    className = className + " configLoadingActiveItemBox";
                  }
                }

                return (
                  <div className={className}>
                    <div className="configItemCard">
                      <div className="itemIconBox">
                        <img src={_?.full_risk_logo_url} alt="" />
                      </div>

                      <div className="itemInfoBox">
                        <div className="numberBox">
                          <span>{_?.children?.length}</span>条
                        </div>
                        <div className="tagBox">
                          <span>{_?.risk_item_name}</span>
                          <Tooltip
                            title={
                              <>
                                {_?.children?.map((_) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "12px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {_?.risk_item_name}
                                  </div>
                                ))}
                              </>
                            }
                            placement="top"
                          >
                            <Tag>核查项</Tag>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="itemLineBox"></div>
                    </div>
                    <div className="configItemLine"></div>
                  </div>
                );
              })}
            </div>
          </div>
        </Scrollbars>
      )}
    </div>
  );
};
export default RiskVerification;
