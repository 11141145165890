import "./index.scss";
import { Layout, message, Tooltip } from "antd";
// import MyHeader from "../../../components/Header";
import MyHeader from "../../../components/MyHeader";
import ProjectInfo from "../ProjectInfo";
import Scrollbars from "react-custom-scrollbars";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import PrivateRoute from "../../../components/PrivateRoute";
import ProgressRouter from "../components/ProgressRouter";
import MySidebar from "../components/MySidebar";
import routers from "../router/router";
import MySpin from "../../../components/MySpin";
import ProjectApi from "../../../apis/project/index";
import { useDispatch, useSelector } from "react-redux";
import { setAdmins, setUserInfo } from "../../../store/user/user";
import {
  setProjectInfo,
  setProjectProgressCodeList,
  setProjectProgressCode,
  setProjectProgressShowCode,
  setProjectProgressName,
  setProjectProgressInfo,
  setMenusList,
  setFirst,
} from "../../../store/project/project";
import UserApi from "../../../apis/user/index";
import { removeToken, setRedirectUrl, setToken } from "../../../unit/index";
import projectApi from "../../../apis/project";
import icon from "../../../assets/概览.svg";
import OperationRecordContent from "../../../components/OperationRecordDrawer";
import { useUnmount } from "ahooks";
import { ExclamationCircleFilled } from "@ant-design/icons";
import homeIcon from "../../../assets/homeIcon.svg";
import MenuLoadingPage from "../../../components/MenuLoadingPage";
import VerificationReportPage from "../VerificationReport /VerificationReportPage";

const { Content } = Layout;

message.config({
  top: 10,
  duration: 3,
  maxCount: 1,
});

const CompanyHome = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  // const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [menuList, setMenuList] = useState([]);
  const [personalMenus, setPersonalMenus] = useState(null);
  const [licenseName, setLicenseName] = useState(null);

  const [loading, setLoading] = useState(true);
  const userInfo = useSelector((state) => state.User.userInfo) || {};

  const data = useSelector((state) => state.Project.projectInfo);
  const projectProgressCode = useSelector(
    (state) => state.Project.projectProgressCode
  );
  const projectProgressShowCode = useSelector(
    (state) => state.Project.projectProgressShowCode
  );
  const projectInfo = useSelector((state) => state.Project.projectInfo);
  const first = useSelector((state) => state.Project.first);

  const [pageLoading, setPageLoading] = useState(true);

  useUnmount(() => {
    clearTimeout(timer.current);
  });
  const initMenu = (menuList) => {
    let notJurisdiction = false;
    menuList = menuList.map((item) => {
      if (item.function_name === "概览") {
        item.menu_url = `/company/${id}/`;
      }
      if (item.children && item.children.length > 0) {
        item.children = item.children.map((_item) => {
          let route = routers.find(
            (r) =>
              r.menu_name === _item.function_name ||
              r.menu_code === _item.function_code
          );

          if (route) {
            _item.menu_url = `/company/${id + route.menu_url}`;
          } else {
            _item.menu_url = `/company/${id}/404`;
          }
          if (_item.menu_url === location.pathname && route) {
            notJurisdiction = _item.disable;
          }
          return _item;
        });
      }
      return item;
    });
    return {
      _menuList: menuList,
      notJurisdiction,
    };
  };

  const getAdminsFunc = async () => {
    projectApi.getAdmins().then((res) => {
      dispatch(setAdmins(res?.data || []));
    });
    //
  };

  useEffect(() => {
    const { _menuList, notJurisdiction } = initMenu(menuList);
    dispatch(setMenusList(JSON.parse(JSON.stringify(_menuList))));
    if (notJurisdiction) {
      navigate(`/company/${id}/`);
    }
  }, [menuList]);

  const getUserInfo = async () => {
    let { data } = await UserApi.getUserInfo();
    if (!data) {
      logout();
      return;
    }
    dispatch(setUserInfo(data));
  };

  const logout = () => {
    let redirect = window.location.href; //origin + "/login";
    UserApi.logout(redirect).then((res) => {
      // console.log(res.data.data.logout_url);
      removeToken();
      if (window.location.pathname !== "/login") {
        setRedirectUrl(window.location.href);
      }
      if (res?.data?.status === "redirect") {
        window.location.href = res.data.logout_url;
      }
      if (res.code == "3") {
        window.location.reload();
      }
    });
  };

  const getMenuTreeData = () => {
    return projectApi.getMenuTreeData("CDD_SCENE_TYPE_ALL").then((res) => {
      let data = [];
      if (res.code === 200) {
        data = res.data.data;
      }
      return data;
    });
  };

  //获取菜单
  const getMenuOfPersonal = () => {
    UserApi.getMenuOfPersonal().then((res) => {
      console.log(res);
      if (res.code === 200) {
        setPersonalMenus(res.data?.menu_list);
        setLicenseName(res.data?.license_name);
      } else {
        setPersonalMenus([]);
        setLicenseName(null);
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (pageLoading) {
      return;
    }
    getUserInfo();
    getMenuOfPersonal();
    getAdminsFunc();
  }, [pageLoading]);

  useEffect(() => {
    if (id === undefined) {
      // navigate("/projectManagement");
      return;
    }
    //因为 projectProgressCode 项目进度会变化时会查询新的项目详情,所以这儿不用再次调
    // getProjectInfo();
    // console.log('--------Home--id-调取------',id);
    getCodeList();
    getProjectProgress();
  }, [id]);

  useEffect(() => {
    console.log("=========", projectProgressCode, projectProgressShowCode);
    if (projectProgressCode === undefined) {
      return;
    }
    if (projectProgressShowCode === undefined) {
      return;
    }
    if (first) {
      setTimeout(() => {
        dispatch(setFirst(false));
      }, 2000);
    }
    getProjectInfo();
  }, [projectProgressCode, projectProgressShowCode]);

  const getCodeList = () => {
    projectApi.getDicCodeInfo("CDD_FIRST_PROCESS").then((res) => {
      if (res.code === 200) {
        const list =
          res.data?.filter(
            (_) => !["CDD_FIRST_PROCESS_6_FINISH"].includes(_.dic_item_code)
          ) || [];
        dispatch(setProjectProgressCodeList(list));
      } else {
        dispatch(setProjectProgressCodeList([]));
      }
    });
  };

  const timer = useRef(null);

  const getShowCodeFunc = (arr, dic_item_code) => {
    let showCode = null;
    if (
      dic_item_code === "CDD_FIRST_PROCESS_1_START" ||
      dic_item_code === "CDD_FIRST_PROCESS_2_BASICINFO"
    ) {
      arr?.forEach((item) => {
        (item?.value || [])?.forEach((_item) => {
          if (_item?.status === "已完成") {
            showCode = _item?.show_code || null;
          }
        });
      });
    }
    return showCode;
  };

  const getProjectProgress = () => {
    projectApi.getProjectProgress(id).then((res) => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      timer.current = setTimeout(() => {
        console.log("获取进度...,当前进度为:", res.data);
        getProjectProgress();
      }, 5000);

      if (res.code === 200) {
        // if (res.data?.dic_item_code !== "CDD_FIRST_PROCESS_6_FINISH") {
        // timer.current = setTimeout(() => {
        //   console.log("获取进度...,当前进度为:", res.data);
        //   getProjectProgress();
        // }, 5000);
        // }
        // console.log('-------',res.data?.dic_item_code);
        dispatch(setProjectProgressCode(res.data?.dic_item_code));
        dispatch(setProjectProgressInfo(res.data?.process_list || []));
        dispatch(setProjectProgressName(res.data?.dic_item_name));
        dispatch(
          setProjectProgressShowCode(
            getShowCodeFunc(
              res.data?.process_list || [],
              res.data?.dic_item_code
            )
          )
        );
      } else {
        dispatch(setProjectProgressInfo([]));
        dispatch(setProjectProgressCode(null));
        dispatch(setProjectProgressShowCode(null));
        dispatch(setProjectProgressName(null));
      }
    });
  };

  const initMenuList = async (list) => {
    let allMenu = await getMenuTreeData();
    // setMenuList(data);
    allMenu = allMenu.map((item) => {
      let _item = list.find((_) => _.function_code === item.function_code);
      if (!_item) {
        item.disable = true;
        item.children = item?.children.map((_) => ({ ..._, disable: true }));
      } else {
        item.children = item.children.map((childrenItem) => {
          let _childrenItem = _item?.children.find(
            (_) => _.function_code === childrenItem.function_code
          );
          if (!_childrenItem) {
            childrenItem.disable = true;
          } else {
            childrenItem = { ...childrenItem, ..._childrenItem };
          }
          return childrenItem;
        });
      }
      return item;
    });
    allMenu.unshift({
      disable: false,
      id: 9999999,
      function_name: "概览",
      children: [],
      full_logo_url: icon,
    });
    setMenuList(allMenu);
  };

  useEffect(() => {
    if (data.function_list) {
      initMenuList(data.function_list);
    }
    document.title = `${
      data.project_name ? data.project_name + "-" : ""
    }项目详情-股权尽调`;
  }, [data]);

  const getProjectInfo = () => {
    ProjectApi.getProjectInfo(id).then((res) => {
      if (res.code === 200) {
        setLoading(false);
        if (res.data.data.length === 0) {
          message.warning(
            res.data?.is_valid === 0 ? "该项目已被删除" : "该项目不存在"
          );
          navigate("/");
          return;
        }
        dispatch(setProjectInfo(res.data.data[0]));
        // setData(res.data.data[0]);
        // initMenuList(res.data.data[0].function_list);
      } else {
        message.error(res.message);
        navigate("/");
      }
    });
  };

  //切换企业成功回调
  const switchEnterpriseSuccessFunc = (data) => {
    console.log("switchEnterpriseSuccessFunc", data);
    setToken(data.token);
    navigate("/");
    window.location.reload();
    // setToken(data?.data.token || '');
    // setCompany(data?.data?.company || '');
    // setSuperGroup(data?.data.super_group || false);
    // window.location.reload()
  };

  const contactBusinessCallBack = () => {
    console.log("联系商务");
    if (localStorage.getItem("deploy") === "BOX") {
      return;
    }
    window.open(localStorage.getItem("sub_url"), "sub_url");
  };

  const menuClickCallBack = (item) => {
    if (item.menu_name === "帮助中心") {
      window.open(
        item.menu_url + localStorage.getItem("product_name"),
        item?.menu_name
      );
      return true;
    }
  };

  if (pageLoading) {
    return <MenuLoadingPage setPageLoading={setPageLoading}></MenuLoadingPage>;
  }

  if (loading) {
    return <MySpin></MySpin>;
  }

  return (
    <div className="companyHomeContent">
      <MyHeader
        logoDom={
          <span className="left-title-concent">
            <Tooltip overlayClassName="headerTipContent" title="回到首页">
              <div
                className="homeBox"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <img src={homeIcon} alt="" className="homeIcon" />
                <div className="homeTip">股权尽调</div>
              </div>
            </Tooltip>

            <div className="xie">/</div>
            <Tooltip
              overlayClassName="headerTipContent"
              title={
                <div className="headerTip">
                  <div className="headerTipTitle">{data.project_name}</div>
                  <div className="headerTipText">
                    尽调对象: {data.company_name}
                  </div>
                  <div className="headerTipText">
                    尽调场景: {data.scene_code_name}
                  </div>
                </div>
              }
            >
              <div className="projectName">{data.project_name}</div>
            </Tooltip>
          </span>
        }
        logoUrl={localStorage.getItem('logo_url')}
        userInfo={userInfo}
        personalMenus={personalMenus}
        logoutBack={logout}
        getUnreadNoticesFunc={projectApi.getUnreadNotices}
        getNoticesFunc={projectApi.getNotices}
        setReadNoticesFunc={projectApi.setReadNotices}
        deleteNoticesFunc={projectApi.deleteNotices}
        getUpdateLogList={projectApi.getUpdateLogList}
        getEnterpriseFunc={projectApi.getEnterprise}
        switchEnterpriseFunc={projectApi.switchEnterprise}
        switchEnterpriseCallBack={switchEnterpriseSuccessFunc}
        licenseName={licenseName}
        projectCode="CDD_PROD"
        getAuthTimeFunc={projectApi.getLicenseValid}
        getAdminsFunc={projectApi.getAdmins}
        contactBusinessCallBack={contactBusinessCallBack}
        menuClickCallBack={menuClickCallBack}
        editCompName={projectApi.editCompName}
        getLicenseFunc={projectApi.getLicense}
        // showNotice={false}
      ></MyHeader>

      {/* 核查报告界面单独分开 */}
      {location?.pathname?.includes("/verificationReport") ? (
        <VerificationReportPage></VerificationReportPage>
      ) : (
        <Layout className="my-layout">
          {/* <MyHeader
          title={data.project_name}
          company_name={data.company_name}
          scene_code_name={data.scene_code_name}
          showTip={true}
        ></MyHeader> */}

          {projectInfo?.status === "CDD_PRJ_STATUS_CLS" ? (
            <div className="closeBox">
              <ExclamationCircleFilled />
              该项目已经关闭，数据停止更新与修改（如需继续尽调，请项目管理员重新启用项目）
            </div>
          ) : projectInfo?.company_type ? (
            <div className="stateRunBox">
              <ExclamationCircleFilled />
              当前尽调对象为{projectInfo?.company_type}
              ，暂无法获取更多尽调详情！
            </div>
          ) : null}

          <div
            className="contentBox"
            style={{
              height: `calc(100% - ${
                projectInfo?.status === "CDD_PRJ_STATUS_CLS" ||
                projectInfo?.company_type
                  ? "32"
                  : "0"
              }px)`,
            }}
          >
            <MySidebar></MySidebar>
            <Content className="my-content">
              {/* {element} */}
              <Scrollbars
                className="scrollbars"
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="contentBox">
                  <Routes key={location.key}>
                    {routers.map((item) => (
                      <Route
                        path={item.menu_url}
                        key={item.menu_url}
                        element={
                          <PrivateRoute>
                            <ProgressRouter menu_name={item.menu_name}>
                              {item.element}
                            </ProgressRouter>
                          </PrivateRoute>
                        }
                      />
                    ))}
                  </Routes>
                  {/* <ProjectInfo></ProjectInfo> */}
                </div>
              </Scrollbars>
            </Content>
          </div>
          <OperationRecordContent routers={routers}></OperationRecordContent>
        </Layout>
      )}
    </div>
  );
};
export default CompanyHome;
